<template>
  <div class="wrapper-content">
    <div class="detail">
      <div class="detail-image">
        <el-image
          :src="loadUrl(eventsInfo.img)"
          fit="cover"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div></el-image
        >
      </div>
      <div class="detail-right">
        <h2>
          {{ eventsInfo.competitionName }}
          <el-tag
            v-if="eventsInfo.applyStatus == 1"
            type="success"
            effect="dark"
            size="mini"
          >
            筹备中
          </el-tag>
          <el-tag
            v-if="eventsInfo.applyStatus == 2"
            type="danger"
            effect="dark"
            size="mini"
          >
            报名中
          </el-tag>
          <el-tag
            v-if="eventsInfo.applyStatus == 3"
            type="info"
            effect="dark"
            size="mini"
          >
            已结束
          </el-tag>
        </h2>
        <div class="detail-right-textbg">
          <p>
            <span class="name">比赛时间：</span>
            <span class="value"
              >{{ formatDateYMD(eventsInfo.matchBeginDate) }} -
              {{ formatDateYMD(eventsInfo.matchEndDate) }}</span
            >
          </p>
          <p>
            <span class="name">比赛地点：</span>
            <span class="value">{{ showAddress(eventsInfo) }}</span>
          </p>
          <p>
            <span class="name">报名费用：</span>
            <span class="value red fz22">
              <i class="f12">¥</i>{{ eventsInfo.applyPay }}</span
            >
          </p>
        </div>
        <div class="detail-right-text">
          <p>
            <span class="name">报名时间：</span>
            <span class="value"
              >{{ formatDateYMD(eventsInfo.applyBeginDate) }} -
              {{ formatDateYMD(eventsInfo.applyEndDate) }}</span
            >
          </p>
          <p>
            <span class="name">主办方：</span>
            <span class="value">{{ eventsInfo.organizerName }}</span>
          </p>
          <p>
            <span class="name">承办方：</span>
            <span class="value">{{ eventsInfo.undertakeName }}</span>
          </p>
        </div>
        <ul class="detail-item-list">
          <li>赛事组别：</li>
          <li v-for="gItem in groupList" :key="gItem.id">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                {{ gItem.needPay == 1 ? gItem.goodsPrice : "" }}
                {{ gItem.groupName }}
              </div>
              <el-button type="text" size="mini">
                {{ gItem.needPay == 1 ? gItem.goodsPrice : "" }}
                {{ gItem.groupName }}</el-button
              >
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>

    <div class="steps-content">
      <div class="steps-content-2">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            v-for="(oItem, index) in orderList"
            :key="index"
            :title="`第 ${index + 1} 次报名`"
            :name="index + 1"
          >
            <div class="step-title">报名信息</div>
            <ul class="step-order-info">
              <li>
                <span class="name">订单类型：</span>
                <span class="value">{{
                  oItem.orderInfo.goodsTypeCode == "104" ? "培训" : "赛事"
                }}</span>
              </li>
              <li>
                <span class="name">报名费用：</span>
                <span class="value danger"
                  >￥ {{ oItem.orderInfo.originalPrice }} 元</span
                >
              </li>
              <li>
                <span class="name">培训名称：</span>
                <span class="value"> {{ eventsInfo.competitionName }}</span>
              </li>
              <li>
                <span class="name">创建时间：</span>
                <span class="value">{{
                  formatDateTime(oItem.orderInfo.createTime)
                }}</span>
              </li>
            </ul>
            <div class="step-title">
              报名人员 （ 共
              <i class="danger">{{ oItem.table.length }}</i> 位报名者）
            </div>
            <el-table
              ref="multipleTable"
              :data="oItem.table"
              class="tab_apply"
              tooltip-effect="dark"
            >
              <el-table-column fixed type="index" label="序号">
              </el-table-column>
              <el-table-column prop="participant" label="报名人员" width="140">
              </el-table-column>
              <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">{{
                  scope.row.sex == 1 ? "男" : "女"
                }}</template>
              </el-table-column>
              <el-table-column prop="identityCode" label="证件号码" width="240">
              </el-table-column>
              <el-table-column prop="phone" label="手机号"> </el-table-column>
              <el-table-column prop="itemNames" label="项目">
                <template slot-scope="scope">
                  <span v-for="(mItem,index) in scope.row.itemNames" :key="index">{{mItem}}</span>
                  </template></el-table-column>
              <el-table-column prop="checkStatus" label="状态">
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.confirmState == 1"
                    type="warning"
                    effect="dark"
                    size="mini"
                  >
                    待确认
                  </el-tag>
                  <el-tag
                    v-if="scope.row.confirmState == 2"
                    type="success"
                    effect="dark"
                    size="mini"
                  >
                    已确认
                  </el-tag>
                  <el-tag
                    v-if="scope.row.confirmState == 3"
                    type="danger"
                    effect="dark"
                    size="mini"
                  >
                    不同意
                  </el-tag>
                  <el-tag
                    v-if="scope.row.confirmState == 4"
                    type="primary"
                    effect="dark"
                    size="mini"
                  >
                    未确认
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column prop="orderPrice" fixed="right" label="报名费用">
                <template slot-scope="scope">
                  ￥{{ scope.row.orderPrice }}
                  <span class="status-price">
                    ({{
                      scope.row.discountType == 0
                        ? "原价"
                        : scope.row.discountType == 1
                        ? "非会员优惠"
                        : scope.row.discountType == 2
                        ? "非会员折扣"
                        : scope.row.discountType == 3
                        ? "会员折扣"
                        : "会员优惠"
                    }})
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div class="apply-order">
              <h2>支付金额</h2>
              <p>
                <span class="name">报名费用：</span>
                <span class="value">￥{{ oItem.orderInfo.originalPrice }}</span>
              </p>
              <p>
                <span class="name">优惠：</span>
                <span class="value"
                  >-￥{{
                    oItem.orderInfo.originalPrice - oItem.orderInfo.orderPrice
                  }}</span
                >
              </p>

              <p class="danger">
                <span class="name">实付金额：</span>
                <span class="value">￥{{ oItem.orderInfo.orderPrice }}</span>
              </p>
            </div>

            <div class="step-title">支付信息</div>
            <ul class="step-order-info">
              <li>
                <span class="name">支付方式：</span>
                <span class="value">{{
                  oItem.orderInfo.payChannel == "wx" ? "微信支付" : "支付宝支付"
                }}</span>
              </li>
              <li>
                <span class="name">{{ oItem.orderInfo.payChannel == "wx" ? "微信" : "支付宝"}}交易号：</span>
                <span class="value">{{ oItem.orderInfo.orderNumber }}</span>
              </li>
              <li>
                <span class="name">付款时间：</span>
                <span class="value">{{
                  formatDateTime(oItem.orderInfo.payTime)
                }}</span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { endRegistrationProcess } from "@/api/events.js";
import { CodeToText } from "element-china-area-data";
export default {
  name: "events-order-info",
  data() {
    return {
      itemId: "",
      eventsInfo: {},
      orderList: [],
      groupList: [],
      tableData: [],
      activeName: 1,
    };
  },

  mounted() {
    this.itemId = this.$route.query.itemId;
    this.myendRegistrationProcess();
  },
  methods: {
    // 获取订单报名信息列表
    myendRegistrationProcess() {
      const self = this;

      self.$store.commit("showLoading");
      this.tableData = [];
      endRegistrationProcess({ itemId: this.itemId }).then((res) => {
        if (res.code == 200) {
          this.eventsInfo = res.data;
          this.groupList = res.data.groupList;
          this.orderList = res.data.orderList;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // 回显地址
    showAddress(item) {
      return `${CodeToText[item.province]}${CodeToText[item.city]}${
        CodeToText[item.region]
      }${item.address}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 44px;
  .detail-image {
    flex-shrink: 0;
    width: 386px;
    height: 283px;
    margin-right: 30px;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .detail-right {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #555555;
    h2 {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      span {
        vertical-align: top;
      }
    }
  }

  .detail-right-text,
  .detail-right-textbg {
    padding: 4px 8px;
    line-height: 26px;
    margin-bottom: 10px;
    p {
      display: flex;
      align-items: top;
    }
    .name {
      flex-shrink: 0;
      width: 70px;
      text-align: right;
    }
    .value {
      // flex: 1;
    }
    .mPrice {
      color: #dd9700;
      font-size: 14px;
      flex: 1;
    }
    .red {
      color: #ff1d00;
    }
    .fz22 {
      font-size: 22px;
    }
    i.f12 {
      font-size: 12px;
    }
  }
  .detail-right-textbg {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 31px;
    background: url("../../assets/image/detail-textbg.png") center no-repeat;
  }
}

.steps-bar {
  width: 100%;
  height: 52px;
  padding: 14px 0;
  margin-top: 40px;
  background: url("../../assets/image/detail-signupbg.png") center no-repeat;
}
.steps-content {
  width: 100%;
  padding-top: 30px;
  min-height: 400px;
  .steps-content-1 {
    min-height: 400px;
  }
  .tab_apply {
    width: 100%;
    margin-top: 30px;

    /deep/ .el-table__header th {
      background: #fafafa;
      border-top: 1px solid #e9ecee;
    }
  }
  .table-btn_bar {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #e9ecee;
    .apply-num {
      padding-left: 16px;
      font-size: 14px;
      color: #555555;
    }
    .all-price {
      text-align: right;
      font-size: 16px;
      color: #555;
      .fz22 {
        font-size: 22px;
      }
    }
  }
}
.btn-steps {
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-apply_item {
  .search-bar {
    padding-top: 0;
  }
}

.step-content-info {
  padding-left: 20px;
  line-height: 36px;
  font-size: 14px;
  color: #555;
  h2 {
    font-size: 24px;
    color: #333;
  }
  .fz22 {
    font-size: 22px;
  }
}

.steps-content-2,
.steps-content-3 {
  border: 1px solid #e4e4e4;
  padding: 20px;
  background: #ffffff;
}
.step-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.step-order-info {
  background: #f8f8f8;
  padding: 10px 20px;
  margin: 20px 0;
  line-height: 34px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 50%;
  }
}

.detail-item-list {
  padding-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 132px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 9px;
    font-size: 14px;
    .el-button--text {
      color: #333333;
      padding: 0 12px;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      text-overflow: ellipsis;
      width: 141px;
    }
    &:first-child {
      flex-shrink: 0;
      width: 70px;
      height: 15px;
      line-height: 15px;
      color: #999999;
      border: 0;
    }
    &:hover,
    &.onSel {
      border-color: #1e50ae;
      color: #1e50ae;
      cursor: pointer;
    }
    &.onSel {
      position: relative;
      background-color: #fff;
      &:before {
        position: absolute;
        font-family: iconfont;
        content: "\ec9e";
        bottom: 0;
        right: 1px;
        font-size: 10px;
        line-height: 10px;
        color: #fff;
        z-index: 4;
      }
      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 18px solid #1e50ae;
        border-left: 20px solid transparent;
      }
    }
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }
  /deep/ .el-form-item__label {
    text-align: left;
  }
  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}
.step-detail {
  display: flex;
  padding: 20px 0;
  .step-content-image {
    width: 214px;
    height: 156px;
    overflow: hidden;
  }
}
.status-price {
  color: #666;
}
.apply-tip {
  color: #999999;
  font-size: 14px;
  line-height: 32px;
}
.apply-order {
  margin-top: 30px;
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  color: #555555;
  line-height: 32px;
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .name {
    display: inline-block;
    width: 128px;
  }
  .value {
    display: inline-block;
  }
}
</style>
